import Vue from 'vue';

import CopyField from '@/components/CopyField';

const fieldsMap = {
  // transfer_method: 'Transfer method',
  // CARD
  card: 'Card number',
  card_holder: 'Card holder',
  valid_thru: 'Card expired date',
  card_country: 'Card country',
  //CRYPTO
  wallet: 'Wallet address',
  network: 'Crypto network',
  memo: 'MEMO',
  // BANK
  account_name: 'Account name',
  account_number: 'Account number/IBAN',
  bank_swift: 'Bank SWIFT/BIC',
  branch_address: 'Branch address',
  bank_country: 'Bank country',
  bank_name: 'Bank name',
  currency: 'Currency code',
  // COMMON
  comment: 'Transaction comment',
};

const fieldsFormatters = {
  network: value => {
    switch (value) {
      case 'bsc':
        return 'BSC Crypto Network';
      case 'btc':
        return 'BTC Bitcoin';
      case 'bnb':
        return 'BNB Crypto Network';
      case 'segwitbtc':
        return 'Segregated Witness (SegWit)';
      default:
        return value;
    }
  },
  card: value => {
    return ('split' in value ? value : undefined)
      ?.split('')
      .reduce(
        (acc, val) => {
          const last = acc.pop();
          if (last.length === 4) {
            acc.push(last);
            acc.push([val]);
          } else {
            last.push(val);
            acc.push(last);
          }
          return acc;
        },
        [[]]
      )
      .map(val => val.join(''))
      .join(' ');
  },
  currency: value => value.toLocaleUpperCase(),
  default: value => value,
};

export default Vue.component('WithdrawCardFields', {
  functional: true,
  name: 'WithdrawCardFields',
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  render(v, { props: { fields } }) {
    const renderFields = Object.entries(fieldsMap).reduce(
      (acc, [key, value]) => {
        if (key in fields && !!fields[key]) {
          acc[value] = fieldsFormatters[
            key in fieldsFormatters ? key : 'default'
          ](fields[key]);
        }
        return acc;
      },
      {}
    );
    return v(
      'div',
      {
        class: 'd-flex flex-column',
      },
      Object.entries(renderFields).map(([key, value]) =>
        v('span', [`${key}: `, v(CopyField, { props: { value } })])
      )
    );
  },
});
